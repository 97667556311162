.altai-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}
.altai-tooltip .altai-tooltip-text {
  visibility: hidden;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.altai-tooltip:hover .altai-tooltip-text {
  visibility: visible;
}
.altai-theme-content #bodyView .bg--white .tooltipcalendar {
  color: #fff;
  text-align: center;
  padding: 0 5px!important;
}
.altai-theme-content #bodyView .bg--white .tooltipcalendar .title {
  color: #fff!important;
  font-weight: 600;
  text-align: left;
}
.altai-theme-content #bodyView .bg--white .tooltipcalendar .description {
  color: #fff;
  text-align: left;
  font-size: 85%;
}
.altai-theme-content #bodyView .bg--white .tooltipcalendar .description p {
  color: #fff;
}
